<template>
  <div class="bank-card-box">
    <iframe :src="url" class="bankIfarme" />
  </div>
</template>

<script>
import { getAgreementByCode } from '@/api/user'
export default {
  data() {
    return {
      url: ''
    }
  },
  mounted() {
    this.getAgreement()
  },
  methods: {
    async getAgreement() {
      const res = await getAgreementByCode({ code: 'ym006' })
      this.url = res.datas.content
    }
  }
}
</script>

<style lang="scss" scoped>
.bank-card-box {
  width: 100%;
  height: 100%;
  .bankIfarme {
    width: 100%;
    height: 100%;
    border: 0;
  }
}
</style>
